import React from "react"

import WebsiteIcon from "../../images/icons/Website"
import BookIcon from "../../images/icons/Book"
import MovieIcon from "../../images/icons/Movie"
import FacebookIcon from "../../images/icons/Facebook"
import InstagramIcon from "../../images/icons/Instagram"

const Source = ({ source }) => {
  const renderIcon = sourceType => {
    switch (sourceType) {
      case "website":
        return <WebsiteIcon />
      case "instagram":
        return <InstagramIcon />
      case "facebook":
        return <FacebookIcon />
      case "book":
        return <BookIcon />
      case "movie":
        return <MovieIcon />
    }
  }

  const renderSource = source => {
    if (source.url && source.sponsored) {
      return (
        <>
          {renderIcon(source.type.type)}
          Source:
          <a target="_blank" href={source.url} rel="nofollow sponsored">
            {source.name}
          </a>
        </>
      )
    } else if (source.url) {
      return (
        <>
          {renderIcon(source.type.type)}
          Source:
          <a target="_blank" href={source.url}>
            {source.name}
          </a>
        </>
      )
    }

    return (
      <>
        {renderIcon(source.type.type)} Source: {source.name}
      </>
    )
  }

  return <div className="card-internal-source">{renderSource(source)}</div>
}

export default Source
