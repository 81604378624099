import React, { useState } from "react"
import { Link } from "gatsby"

import GlobeIcon from "../../images/icons/Globe"

import "../LangSelect.css"

const LangSelect = () => {
  const [open, setOpen] = useState(false)

  return (
    <div className="lang-select">
      <button onClick={() => setOpen(!open)}>
        <GlobeIcon />
      </button>

      <ul style={{ display: open ? "block" : "none" }}>
        <li className="active">
          <Link to="/en">ENGLISH</Link>
        </li>
        <li>
          <Link to="/pt">PORTUGUÊS</Link>
        </li>
      </ul>
    </div>
  )
}

export default LangSelect
