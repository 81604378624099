import React from "react"
import { Link } from "gatsby"

import { TelegramIcon } from "react-share"

import HomeIcon from "../../images/icons/Home"
import FeedIcon from "../../images/icons/Feed"
import TopicsIcon from "../../images/icons/Topics"
import CollectionsIcon from "../../images/icons/Collections"
import TrendIcon from "../../images/icons/Trend"
import HashtagIcon from "../../images/icons/Hashtag"
import FacebookIcon from "../../images/icons/Facebook"
import InstagramIcon from "../../images/icons/Instagram"
import MadeWithIcon from "../../images/icons/MadeWith"

import logoImage from "../../images/nizam.png"

import "./Navigation.css"

const Navigation = () => {
  return (
    <aside className="desktop-aside">
      <Link to="/en" className="menu-logo" partiallyActive={true}>
        <img src={logoImage} alt="Nizam" />
      </Link>

      <nav>
        <Link
          to="/en"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <HomeIcon />
          </div>
          <span>Home</span>
        </Link>

        <Link
          to="/en/cards"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon">
            <FeedIcon />
          </div>
          <span>Feed</span>
        </Link>

        <Link
          to="/en/topics"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <TopicsIcon />
          </div>
          <span>Topics</span>
        </Link>

        <Link
          to="/en/collections"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <CollectionsIcon />
          </div>
          <span>Collections</span>
        </Link>

        <Link
          to="/en/trending"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <TrendIcon />
          </div>
          <span>Trending</span>
        </Link>

        <Link
          to="/en/hashtags"
          className="nav-tab-item"
          activeStyle={{ backgroundColor: "#103d45", color: "#fff" }}
          activeClassName="nav-tab-active"
        >
          <div className="nav-tab-icon" style={{ height: 24 }}>
            <HashtagIcon />
          </div>
          <span>Hashtags</span>
        </Link>
      </nav>

      <hr
        style={{ border: "none", height: 1, background: "#DBE2EA", margin: 20 }}
      />

      <div className="navigation-bottom">
        <div className="navigation-bottom-social">
          <a href="https://www.facebook.com/nizamapp" target="_blank">
            <FacebookIcon height={40} round />
          </a>

          <a href="https://www.instagram.com/nizam.app" target="_blank">
            <InstagramIcon size={40} round />
          </a>
        </div>

        <div className="nav-static-links">
          <Link to="/en/about-us" target="_blank">
            About us
          </Link>
          <Link to="/en/terms-of-service" target="_blank">
            Terms of Service
          </Link>
          <Link to="/en/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
        </div>
      </div>

      <a href="https://t.me/nizamapp" target="_blank" className="cta">
        Sign up on Telegram <TelegramIcon size={32} round />
      </a>

      <p className="nav-made-with">
        © 2020 Nizam | Made with love for <MadeWithIcon width={27} /> by Taha
        Brasil
      </p>
    </aside>
  )
}

export default Navigation
