import React, { useState, useEffect } from "react"

import Header from "./Header"
import Navigation from "./Navigation"
import MobileNavigation from "./MobileNavigation"
import Search from "./Search"
import MobileSearch from "./MobileSearch"
import LangSelect from "./LangSelect"

import "./Layout.css"

const Layout = ({ children }) => {
  const [menuToggle, setMenuToggle] = useState(false)
  const [searchToggle, setSearchToggle] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 1366) {
      setMenuToggle(false)
      setSearchToggle(false)
    }
  }, [])

  return (
    <>
      <Header
        toggleMenu={menuToggle}
        setToggleMenu={setMenuToggle}
        toggleSearch={searchToggle}
        setToggleSearch={setSearchToggle}
      />
      <div className="main-container">
        <main>
          <Search />
          <MobileSearch
            toggleSearch={searchToggle}
            setToggleSearch={setSearchToggle}
          />
          <LangSelect />

          {children}
        </main>

        <Navigation />
        <MobileNavigation
          toggleMenu={menuToggle}
          setToggleMenu={setMenuToggle}
        />
      </div>
    </>
  )
}

export default Layout
