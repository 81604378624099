import React, { useEffect, useState } from "react"
import { Link, graphql, navigate } from "gatsby"

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import Layout from "../../components/en/Layout"
import CardPost from "../../components/en/CardPost"
import CardQuote from "../../components/en/CardQuote"
import ReadMoreCard from "../../components/en/ReadMoreCard"
import Breadcrumbs from "../../components/en/Breadcrumbs"
import SEO from "../../components/en/SEO"

const CardTemplate = ({ data }) => {
  const card = data.cardsEn
  const cardsRandom = data.allCardsRandomEn.edges
    .sort(() => 0.5 - Math.random())
    .slice(0, 4)

  let metaDescription = card.meta.content || card.meta.excerpt
  metaDescription = metaDescription.replace(/(<([^>]+)>)/gi, "").trim()

  if (metaDescription.length >= 120) {
    metaDescription = metaDescription.substring(0, 120)
  }

  return (
    <Layout>
      <SEO
        title={card.name}
        description={metaDescription}
        card={true}
        image={card.imageUrl}
        canonical={`${process.env.GATSBY_HOST_URL}/en/cards/${card.slug}`}
        category={card.topic.name}
        date={card.createdAt}
        dateUpdated={card.updatedAt}
      />

      <div
        className="main-content card-top-main-content"
        style={{ marginTop: 117, marginBottom: 0 }}
      >
        <Breadcrumbs
          secondaryLink={`/en/topics/${card.topic.slug}`}
          secondaryLinkName={card.topic.name}
          currentPage={card.name}
          style={{ marginTop: 0, marginBottom: 30 }}
        />
      </div>

      <div
        className="main-content card-internal-main-content"
        style={{ marginTop: 0 }}
      >
        <div className="card-internal-share card-desktop-internal-share">
          <FacebookShareButton
            url={`${process.env.GATSBY_HOST_URL}/en/cards/${card.slug}`}
            quote={`${card.name} and more you can find on Nizam`}
            hashtag="Nizam"
          >
            <FacebookIcon size={50} round />
          </FacebookShareButton>

          <TwitterShareButton
            url={`${process.env.GATSBY_HOST_URL}/en/cards/${card.slug}`}
            title={`${card.name} and more you can find on Nizam`}
            via="Nizam"
            hashtags={["Nizam"]}
          >
            <TwitterIcon size={50} round />
          </TwitterShareButton>

          <WhatsappShareButton
            url={`${process.env.GATSBY_HOST_URL}/en/cards/${card.slug}`}
            title={`${card.name} and more you can find on Nizam`}
            separator=" - "
          >
            <WhatsappIcon size={50} round />
          </WhatsappShareButton>

          <TelegramShareButton
            url={`${process.env.GATSBY_HOST_URL}/en/cards/${card.slug}`}
            title={`${card.name} and more you can find on Nizam`}
          >
            <TelegramIcon size={50} round />
          </TelegramShareButton>
        </div>

        {card.type === "quote" ? (
          <CardQuote card={card} />
        ) : (
          <CardPost card={card} />
        )}
      </div>

      <div className="main-content" style={{ marginTop: 50 }}>
        <div className="card-internal-read-more">
          <h2>Read more</h2>

          <div className="card-internal-read-more-list">
            {cardsRandom.map(card => (
              <ReadMoreCard card={card} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CardTemplate

export const query = graphql`
  query($id: String!) {
    cardsEn(id: { eq: $id }) {
      name
      slug
      createdAt
      updatedAt
      imageUrl
      type
      source {
        name
        url
        sponsored
        type {
          name
          type
        }
      }
      topic {
        name
        slug
        color
      }
      tags {
        name
        slug
      }
      meta {
        content
        author
      }
    }

    allCardsRandomEn(limit: 180) {
      edges {
        node {
          name
          slug
          imageUrl
          meta {
            content
          }
        }
      }
    }
  }
`
