import React, { useRef } from "react"
import { navigate } from "gatsby"

import SearchIcon from "../../images/icons/Search"

import "./Search.css"

const Search = () => {
  const queryInput = useRef(null)

  const handleSubmit = e => {
    e.preventDefault()
    console.log(queryInput.current.value)

    navigate(`/en/search?s=${queryInput.current.value}`, {
      state: { query: queryInput.current.value },
      replace: true,
    })
  }

  return (
    <div className="search">
      <SearchIcon />

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="query"
          ref={queryInput}
          id="search-text-input"
          placeholder="Find an article..."
        />
      </form>
    </div>
  )
}

export default Search
